<template>
  <label class="g-checkbox" :class="{ disabled }" :for="buttonID">
    <input
      v-bind="{ name, disabled, ...$attrs }"
      :id="buttonID"
      :checked="isChecked"
      :value="value"
      type="checkbox"
      v-on="localListeners"
    />
    <!-- <Checkbox class="icon-checkbox" aria-hidden="true" focusable="false" /> -->
    <div class="checkbox-wrapper" aria-hidden="true" focusable="false" :class="{ checked: isChecked }">
      <slot name="checkbox" v-bind="{ checked: isChecked }">
        <svg class="icon-checkbox" xmlns="http://www.w3.org/2000/svg" :class="{ checked: isChecked }">
          <g id="checkbox" transform="translate(-30 -412)">
            <path
              id="Path_280"
              data-name="Path 280"
              d="M-472.238-4117.711l6.621,6.47,12.379-12"
              transform="translate(503.648 4540.931)"
              fill="none"
              stroke="#fff"
              stroke-width="1"
            />
          </g>
        </svg>
      </slot>
    </div>
    <span v-if="!hideLabel" class="label" :class="labelPosition">
      <!-- @slot used for inserting label. Will default to label prop then value if label not provided -->
      <slot v-bind="{ checked: isChecked, label, value }">
        {{ label || value }}
      </slot>
    </span>
  </label>
</template>

<script>
/* eslint-disable object-shorthand */
// QUESTION should a select also use this format?
// QUESTION should this be part of the g-input component?

/**
 * @version 0.2.0
 */
export default {
  name: 'GCheckbox',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    labelPosition: {
      type: String,
      default: 'after',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    checked: {
      type: [Array, Boolean],
      default: false,
    },
    value: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
  },
  computed: {
    localListeners() {
      return {
        ...this.$listeners,
        change: this.updateCheckbox,
      };
    },
    checkedIsArray() {
      return Array.isArray(this.checked);
    },
    isChecked() {
      if (this.checkedIsArray) {
        return this.checked.includes(this.value);
      }
      return this.checked;
    },
    computedChecked: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    buttonID() {
      return this.id || `g-checkbox-${this._uid}`;
    },
  },
  methods: {
    updateCheckbox(event) {
      // eslint-disable-next-line prefer-destructuring
      let checked;
      if (this.checkedIsArray) {
        checked = [...this.checked];
        const checkedIndex = this.checked.indexOf(this.value);
        if (checkedIndex > -1) {
          checked.splice(checkedIndex, 1);
        } else {
          checked.push(this.value);
        }
      } else {
        checked = !this.checked;
      }
      this.$emit('updated', event);
      this.$emit('change', checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.g-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &,
  * {
    box-sizing: border-box;
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  .label {
    &.after {
      margin-left: 5px;
    }
    &.before {
      order: -1;
      margin-right: 5px;
    }
  }

  $box-size: 24px;
  .icon-checkbox {
    display: block;
    // Tweak font-size to resize width and height
    width: $box-size;
    height: $box-size;
    border: 1px solid #222;
    border-radius: 2px;

    path {
      stroke: transparent;
    }

    &.checked {
      background-color: $primary-white;
      // border-color: $primary-light;
      path {
        stroke: $primary-dark;
      }
    }
  }
  input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;

    /* tweak size and position if needed */
    width: $box-size;
    height: $box-size;
    padding: 0;
    margin: 0;

    /* hide it visually if 0 screen reader will skip allegedly haven't tested */
    opacity: 0.00001;

    &:checked + svg {
      background-color: $primary-white;
      border-color: $primary-light;
      path {
        stroke: $primary-white;
      }
    }
  }
}
</style>
