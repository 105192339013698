<template>
  <div class="legal-consent">
    <span v-for="(line, i) of lines" :key="i">
      <!-- @click="$event.stopPropagation()" -->
      <a v-if="line.link" :href="line.link" target="_blank">{{ line.text }}</a>
      <span v-else-if="line.text">{{ line.text }}</span>
      <br v-if="line.break" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    lines: Array[Object],
  },
};
</script>

<!-- <style lang="scss" scoped></style> -->
